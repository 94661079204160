import { ApolloClient } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import dayjs from 'dayjs';

import { STATUS_PAGE_URL, STATUS_PROXY_URL } from '~/lib/hooks/status-page';

import { addNotification } from '../../add';

import {
  CompletedIncident,
  IncidentBase,
  IncidentImpact,
  IncidentStatus,
  IncidentType,
  Maintenance,
  MaintenanceStatus,
  OngoingIncident,
  Status,
  Summary
} from './types';

const impactIntentMap: Record<IncidentImpact, Intent> = {
  degraded_performance: Intent.WARNING,
  full_outage: Intent.DANGER,
  maintenance_complete: Intent.SUCCESS,
  operational: Intent.SUCCESS,
  partial_outage: Intent.DANGER,
  under_maintenance: Intent.PRIMARY
};

const impactStatusMap: Record<IncidentImpact, string> = {
  degraded_performance: 'Degraded Performance',
  full_outage: 'Full Outage',
  maintenance_complete: 'Maintenance Complete',
  operational: 'Operational',
  partial_outage: 'Partial Outage',
  under_maintenance: 'Under Maintenance'
};

const incidentStatusMap: Record<Status, string> = {
  identified: 'Identified',
  investigating: 'Investigating',
  maintenance_complete: 'Complete',
  maintenance_in_progress: 'In Progress',
  maintenance_scheduled: 'Scheduled',
  monitoring: 'Monitoring',
  resolved: 'Resolved'
};

const incidentTypeMap: Record<IncidentType, string> = {
  incident: 'Incident',
  maintenance: 'Maintenance'
};

/**
 * HOC that returns a filter based on componentIds
 * @param componentIds
 */
function filterByComponentIds(componentIds?: string[]) {
  // Include all incidents if no componentIds are provided
  // This could happen if the API is down
  if (componentIds === undefined) {
    return () => true;
  }

  return function (incidentOrMaintenance: IncidentBase): boolean {
    return incidentOrMaintenance.affected_components.some((component) =>
      componentIds.includes(component.component_id)
    );
  };
}

/**
 *
 * @param client
 * @param componentIdsToName
 * @param incident
 */
export function addIncidentNotification(
  client: ApolloClient<any>,
  componentIdsToName: Record<string, string>,
  incident: Maintenance | OngoingIncident | CompletedIncident
) {
  // These two are always sorted oldest to newest
  const latestUpdate = incident.updates[incident.updates.length - 1];
  const latestStatusSummary =
    incident.status_summaries[incident.status_summaries.length - 1];

  // We show what the short status impact is of each component affected
  let componentStatusMessages = latestUpdate.component_statuses.map(
    (component) =>
      componentIdsToName[component.component_id] +
      ': ' +
      impactStatusMap[component.status]
  );

  // For maintenances, we don't show the component status, we show the component name
  if (incident.type === IncidentType.MAINTENANCE) {
    // We show the affected components differently for maintenances
    componentStatusMessages = latestUpdate.component_statuses.map(
      (component) => componentIdsToName[component.component_id]
    );
  }

  // The latestStatusSummary doesn't get updated when it goes to resolved
  let impact =
    incident.status === IncidentStatus.RESOLVED
      ? IncidentImpact.OPERATIONAL
      : latestStatusSummary.worst_component_status;

  // If it's a maintenance, impact is always under maintenance unless it's complete
  if (incident.type === IncidentType.MAINTENANCE) {
    impact =
      incident.status === MaintenanceStatus.MAINTENANCE_COMPLETE
        ? IncidentImpact.MAINTENANCE_COMPLETE
        : IncidentImpact.UNDER_MAINTENANCE;
  }

  // Notification icon
  let icon =
    incident.status === IncidentStatus.RESOLVED
      ? IconNames.TICK
      : IconNames.WARNING_SIGN;

  // If it's a maintenance, the icon is different
  if (incident.type === IncidentType.MAINTENANCE) {
    icon =
      incident.status === MaintenanceStatus.MAINTENANCE_COMPLETE
        ? IconNames.TICK
        : IconNames.WRENCH;
  }

  // Notification message
  let message = latestUpdate.message_string;

  // Add the start and end times for scheduled maintenances
  if (incident.type === IncidentType.MAINTENANCE) {
    const start = dayjs(latestStatusSummary.start_at);
    const end = dayjs(latestStatusSummary.end_at);

    if (incident.status === MaintenanceStatus.MAINTENANCE_SCHEDULED) {
      message += `\n\nMaintenance Window: ${start.format(
        'DD/MM/YYYY @ HH:mm'
      )} - ${end.format('HH:mm')}.`;
    } else if (incident.status === MaintenanceStatus.MAINTENANCE_IN_PROGRESS) {
      message += `\n\nEnds ${end.format('DD/MM/YYYY @ HH:mm')}.`;
    }
  }

  message += `\n\nAffected Components:\n${componentStatusMessages.join('\n')}`;

  // Make sure there is never more than 2 consecutive newlines. Sometimes there is a space between consecutive newlines
  // So we need to strip that out if it exists too
  message = message.replace(/\n \n/g, '\n\n').replace(/\n{3,}/g, '\n\n');

  // Notification title
  const title =
    incidentTypeMap[incident.type] + // Incident or Maintenance
    '  (' +
    incidentStatusMap[latestUpdate.to_status] + // Identified, Investigating, etc
    '): ' +
    incident.name;

  // Notification intent
  // eslint-disable-next-line security/detect-object-injection
  const intent = impactIntentMap[impact];

  const url = STATUS_PAGE_URL + '/incidents/' + incident.id;

  addNotification(client, {
    force: true,
    handler: ['link', url],
    icon,
    id: incident.id,
    intent,
    message,
    title
  });
}

/**
 * Helper function to fetch incidents/maintenances by their IDs
 *
 * This function never throws and just returns undefined on failure
 * @param id
 */
export async function fetchResolvedById<T>(id: string): Promise<T | undefined> {
  try {
    const response = await fetch(`${STATUS_PROXY_URL}/incidents/${id}`);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch incident with ID ${id}. Status: ${response.status}`
      );
    }
    const data = await response.json();
    return data?.incident as T | undefined;
  } catch (error) {
    // Don't capture, this could fail for so many different reasons
    console.error('Error fetching resolved incident', error);
  }
}

/**
 * Fetch the main summary and status info
 *
 * Maintenances and Incidents are treated the same (mostly), except for scheduled maintenances,
 * which we put together to make it easier to reason with.
 *
 * This function never throws, just returns undefined on failure
 * @param componentIds
 */
export async function fetchData(componentIds?: string[]) {
  try {
    const fetchResponse = await fetch(STATUS_PROXY_URL);
    const response = await fetchResponse.json();
    const summary: Summary = response.summary;

    // Create a mapping of id to name
    const componentIdsToName: Record<string, string> =
      summary.components.reduce(
        (acc, component) => {
          acc[component.id] = component.name;
          return acc;
        },
        {} as Record<string, string>
      );

    const componentFilter = filterByComponentIds(componentIds);

    const scheduledMaintenances =
      summary.scheduled_maintenances.filter(componentFilter);

    const ongoingMaintenances = summary.ongoing_incidents
      .filter((incident) => incident.type === IncidentType.MAINTENANCE)
      .filter(componentFilter);

    // We don't need the maintenances split into two, so concat
    const maintenances = [...scheduledMaintenances, ...ongoingMaintenances];

    const ongoingIncidents = summary.ongoing_incidents
      .filter((incident) => incident.type === IncidentType.INCIDENT)
      .filter(componentFilter);

    return {
      componentIdsToName,
      maintenances,
      ongoingIncidents
    };
  } catch (error: any) {
    // Don't captureException, this could happen for lots of different reasons
    console.error('Unable to fetch status summary data', error);
  }
}
