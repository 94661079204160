export enum IncidentType {
  INCIDENT = 'incident',
  MAINTENANCE = 'maintenance'
}

export enum IncidentStatus {
  IDENTIFIED = 'identified',
  INVESTIGATING = 'investigating',
  MONITORING = 'monitoring',
  RESOLVED = 'resolved'
}

export enum IncidentImpact {
  DEGRADED_PERFORMANCE = 'degraded_performance',
  FULL_OUTAGE = 'full_outage',
  MAINTENANCE_COMPLETE = 'maintenance_complete',
  OPERATIONAL = 'operational',
  PARTIAL_OUTAGE = 'partial_outage',
  UNDER_MAINTENANCE = 'under_maintenance'
}

export enum MaintenanceStatus {
  MAINTENANCE_SCHEDULED = 'maintenance_scheduled',
  MAINTENANCE_IN_PROGRESS = 'maintenance_in_progress',
  MAINTENANCE_COMPLETE = 'maintenance_complete'
}

export type Status = IncidentStatus | MaintenanceStatus;

interface StatusSummary {
  end_at: string;
  start_at: string;
  worst_component_status: IncidentImpact;
}

interface AffectedComponent {
  component_id: string;
  status: IncidentImpact;
}

interface Update {
  published_at: string;
  id: string;
  message: {
    type: string;
    content: Array<{
      type: string;
      content: Array<{ type: string; text: string }>;
    }>;
  };
  message_string: string;
  to_status: Status;
  component_statuses: {
    component_id: string;
    status: IncidentImpact;
  }[];
  automated_update: boolean;
}

interface ComponentImpact {
  component_id: string;
  end_at: string;
  id: string;
  start_at: string;
  status_page_incident_id: string;
  status: IncidentImpact;
}

export interface IncidentBase {
  updates: Update[];
  component_impacts: ComponentImpact[];
  status_summaries: StatusSummary[];
  published_at: string;
  id: string;
  status_page_id: string;
  name: string;
  affected_components: AffectedComponent[];
}

export interface OngoingIncident extends IncidentBase {
  status: Omit<IncidentStatus, IncidentStatus.RESOLVED>;
  type: IncidentType.INCIDENT;
}

interface OngoingMaintenance extends IncidentBase {
  status: MaintenanceStatus.MAINTENANCE_IN_PROGRESS;
  type: IncidentType.MAINTENANCE;
}

interface ScheduledMaintenance extends IncidentBase {
  status: MaintenanceStatus.MAINTENANCE_SCHEDULED;
  type: IncidentType.MAINTENANCE;
}

export interface CompletedIncident extends IncidentBase {
  status: IncidentStatus.RESOLVED;
  type: IncidentType.INCIDENT;
}

export interface CompletedMaintenance extends IncidentBase {
  status: MaintenanceStatus.MAINTENANCE_COMPLETE;
  type: IncidentType.MAINTENANCE;
}

export type Maintenance =
  | ScheduledMaintenance
  | OngoingMaintenance
  | CompletedMaintenance;

interface StructureComponent {
  component_id: string;
  data_available_since: string;
  display_uptime: boolean;
  hidden: boolean;
  name: string;
}

interface StructureItemComponent {
  component: StructureComponent;
}

interface StructureItemGroup {
  group: {
    id: string;
    name: string;
    description: string;
    display_aggregated_uptime: boolean;
    hidden: boolean;
    components: StructureComponent[];
  };
}

type StructureItem = StructureItemComponent | StructureItemGroup;

export interface Summary {
  id: string;
  name: string;
  subpath: string;
  support_url: string;
  support_label: string;
  public_url: string;
  logo_url: string;
  favicon_url: string;
  components: {
    id: string;
    name: string;
    status_page_id: string;
  }[];
  subscriptions_disabled: boolean;
  display_uptime_mode: string;
  allow_search_engine_indexing: boolean;
  affected_components: AffectedComponent[];
  ongoing_incidents: OngoingIncident[] | OngoingMaintenance[];
  scheduled_maintenances: ScheduledMaintenance[];
  structure: {
    id: string;
    status_page_id: string;
    items: StructureItem[];
  };
  privacy_policy_url: string;
  expose_status_summary_api: boolean;
  theme: string;
  locale: string;
  page_type: string;
  data_available_since: string;
}

export interface NotifiedMaintenanceState {
  lastUpdateId: string;
  shownFirstReminder: boolean;
  shownSecondReminder: boolean;
}
