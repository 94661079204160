import mem from 'mem';

import { PermissionCode } from './utils/permission-code';

interface AdminPermissions {
  anprPoiGroups: boolean;
  contacts: boolean;
  edges: boolean;
  edgeGroups: boolean;
  sites: boolean;
  siteGroups: boolean;
  ssoGroups: boolean;
  streams: boolean;
  superadmin: boolean;
  users: boolean;
  userGroups: boolean;
  sessions: boolean;
  logs: boolean;
  vms: boolean;
}

interface AnprPermissions {
  alerts: boolean;
  insights: boolean;
  export: boolean;
  platesOfInterest: boolean;
  platesOfInterestEdit: boolean;
  platesOfInterestUpdate: boolean;
  platesOfInterestCreate: boolean;
  realTime: boolean;
  search: boolean;
}

interface GlimpsePermissions {
  create: boolean;
  oversight: boolean;
  view: boolean;
}

export interface VaultPermissions {
  create: boolean;
  request: boolean;
  update: boolean;
  view: boolean;
}

export interface Permissions {
  admin: AdminPermissions;
  anpr: AnprPermissions;
  glimpse: GlimpsePermissions;
  hasAdmin: boolean;
  hasAnpr: boolean;
  hasHelios: boolean;
  hasStreams: boolean;
  hasVault: boolean;
  pro: boolean;
  vault: VaultPermissions;
}

//  0: ANPR_INSIGHTS
//  1: ANPR_POI_READ
//  2: ANPR_SEARCH
//  3: ANPR_VIEW
//  4: EDGE_GROUP_READ
//  5: EDGE_READ
//  6: GLIMPSE_CREATE
//  7: GLIMPSE_OVERSIGHT
//  8: GLIMPSE_VIEW
//  9: LICENCE_PRO
// 10: LOG_READ
// 11: SESSION_VIEW
// 12: SITE_GROUP_READ
// 13: SITE_UPDATE
// 14: STREAM_UPDATE
// 15: STREAM_VIEW
// 16: USER_CHANGE_PASSWORD
// 17: USER_GROUP_READ
// 18: USER_READ
// 19: VAULT_VIEW
// 20: ANPR_POI_EDIT
// 21: ANPR_POI_UPDATE
// 22: ANPR_POI_CREATE
// 23: ANPR_POI_GROUP_UPDATE
// 24: CONTACT_ADMIN
// 25: VAULT_UPDATE
// 26: VAULT_CREATE
// 27: VAULT_REQUEST
// 28: SUPER_ADMIN
// 29: VMS_READ
// 30: HELIOS_OPERATE
// 31: SSO_GROUP_READ // TODO: Ensure this doesn't overlap with floor plans

/**
 *
 * @param keys
 */
export const getPermissions = mem<(code: string) => Permissions, string>(
  (code) => {
    const data = new PermissionCode(code);

    const admin: AdminPermissions = {
      anprPoiGroups: data.isAllowed(23),
      contacts: data.isAllowed(24),
      edgeGroups: data.isAllowed(4),
      edges: data.isAllowed(5),
      logs: data.isAllowed(10),
      sessions: data.isAllowed(11),
      siteGroups: data.isAllowed(12),
      sites: data.isAllowed(13),
      ssoGroups: data.isAllowed(31),
      streams: data.isAllowed(14),
      superadmin: data.isAllowed(28),
      userGroups: data.isAllowed(17),
      users: data.isAllowed(18),
      vms: data.isAllowed(29)
    };

    const anpr: AnprPermissions = {
      alerts: data.isAllowed(3),
      export: data.isAllowed(2),
      insights: data.isAllowed(0),
      platesOfInterest: data.isAllowed(1),
      platesOfInterestCreate: data.isAllowed(22),
      platesOfInterestEdit: data.isAllowed(20),
      platesOfInterestUpdate: data.isAllowed(21),
      realTime: data.isAllowed(3),
      search: data.isAllowed(2)
    };

    const vault: VaultPermissions = {
      create: data.isAllowed(26),
      request: data.isAllowed(27),
      update: data.isAllowed(25),
      view: data.isAllowed(19)
    };

    return {
      admin,
      anpr,
      glimpse: {
        create: data.isAllowed(6),
        oversight: data.isAllowed(7),
        view: data.isAllowed(8)
      },
      hasAdmin: !!Object.values(admin).filter(Boolean).length,
      hasAnpr: !!Object.values(anpr).filter(Boolean).length,
      hasHelios: data.isAllowed(30),
      hasStreams: data.isAllowed(15),
      hasVault: !!Object.values(vault).filter(Boolean).length,
      pro: data.isAllowed(9),
      vault
    };
  },
  { cacheKey: (args) => args[0] }
);
