import { ApolloClient } from '@apollo/client';
import { IconNames } from '@blueprintjs/icons';
import Push from 'push.js';

import { router } from '../../components/App';

import { addNotification } from './add';

export const handlers: Record<string, (...params: any[]) => void> = {
  /**
   *
   * @param _client
   * @param url
   */
  link(_client: ApolloClient<any>, url: string): void {
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      router.navigate(url);
    }
  },

  /**
   *
   * @param client
   */
  pushRequest(client: ApolloClient<any>): void {
    Push.Permission.request(undefined, (): void =>
      addNotification(client, {
        handler: null,
        icon: IconNames.FLAG,
        id: 'notifications-denied',
        intent: 'warning',
        message:
          'Notifications will not be displayed while your browser is minimised',
        title: 'Notifications denied'
      })
    );
  },

  /**
   *
   */
  reload(): void {
    location.reload();
  }
};
