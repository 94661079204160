import { ApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import { UseStatusPage } from '~/lib/hooks/status-page';

import { captureError } from '../../../utils/sentry';

import {
  CompletedIncident,
  CompletedMaintenance,
  NotifiedMaintenanceState
} from './types';
import { addIncidentNotification, fetchData, fetchResolvedById } from './utils';

const POLL_INTERVAL = 60_000;
const MAINTENANCE_STORAGE_KEY = 'vgrid.statuspage-maintenances-displayed';
const INCIDENT_STORAGE_KEY = 'vgrid.statuspage-incidents-displayed';

/**
 *
 * @param client
 */
async function poll(
  client: ApolloClient<any>,
  componentIds: UseStatusPage['componentIds']
): Promise<void> {
  // We  want to long-term track whether or not the user has seen the maintenance notifications
  // (i.e local storage), so we don't keep showing them every time the session is reloaded
  const notifiedMaintenances = JSON.parse(
    localStorage.getItem(MAINTENANCE_STORAGE_KEY) || '{}'
  ) as Record<string, NotifiedMaintenanceState>;

  // Anything that is an ongoing incident should show every time the session is reloaded
  const notifiedIncidents = JSON.parse(
    sessionStorage.getItem(INCIDENT_STORAGE_KEY) || '{}'
  ) as Record<string, string>;

  // Track everything we see from our fetch, because we need to clear the old
  // notifications, and send a notification as "resolved"
  // This is just an array of the incident id
  const seenIds: string[] = [];

  // This will never throw, just return undefined
  // If componentIds is undefined, we will get all incidents (could have happened because API is down)
  const data = await fetchData(componentIds);

  if (!data) {
    return;
  }

  for (const incident of data.ongoingIncidents) {
    // Have we seen this incident, with this update yet?
    const latestUpdate = incident.updates[incident.updates.length - 1];
    if (
      notifiedIncidents[incident.id] &&
      notifiedIncidents[incident.id] === latestUpdate.id
    ) {
      seenIds.push(incident.id);
      continue; // No need to do anything
    }

    // Haven't seen this incident, or we haven't shown the latest update.
    // Notify, then add to list
    addIncidentNotification(client, data.componentIdsToName, incident);
    notifiedIncidents[incident.id] = latestUpdate.id;
    seenIds.push(incident.id);
  }

  for (const maintenance of data.maintenances) {
    const startsAt = dayjs(maintenance.status_summaries[0].start_at);
    const now = new Date();
    const timeUntilStart = startsAt.diff(now, 'hours');

    // If the maintenance starts more than 72 hours from now, we don't need to notify
    if (timeUntilStart > 72) {
      continue;
    }

    // Check if we have a record of this maintenance
    const latestUpdate = maintenance.updates[maintenance.updates.length - 1];
    const previousNotification = notifiedMaintenances[maintenance.id];

    // Notify if:
    // No previous notification
    // Less than 12 hours, and we haven't reminded yet
    // Less than 1 hour and we haven't done the second reminder
    // The last update we had doesn't match (covers going into in-progress)
    const isFirstReminderDue =
      timeUntilStart < 12 && !previousNotification?.shownFirstReminder;
    const isSecondReminderDue =
      timeUntilStart < 1 && !previousNotification?.shownSecondReminder;
    const shouldTriggerUpdate =
      timeUntilStart < 12 &&
      previousNotification?.lastUpdateId !== latestUpdate.id;

    if (
      !previousNotification ||
      isFirstReminderDue ||
      isSecondReminderDue ||
      shouldTriggerUpdate
    ) {
      addIncidentNotification(client, data.componentIdsToName, maintenance);
      notifiedMaintenances[maintenance.id] = {
        lastUpdateId: maintenance.updates[maintenance.updates.length - 1].id,
        shownFirstReminder: timeUntilStart < 12,
        shownSecondReminder: timeUntilStart < 1
      };
    }

    seenIds.push(maintenance.id);
  }

  // Everything new / existing has been looped, lets check to see if there is anything we now need to notify as "complete"
  const closedIncidentIds = Object.keys(notifiedIncidents).filter(
    (id) => !seenIds.includes(id)
  );

  for (const id of closedIncidentIds) {
    // Need to go and fetch the incident, so we get the data associated with it
    // Remove incident from the list regardless of fetch working
    const incident = await fetchResolvedById<CompletedIncident>(id);

    if (incident) {
      // Don't notify if it has been more than 30m since it went to resolved
      const lastUpdate = incident.updates[incident.updates.length - 1];
      const resolvedAt = dayjs(lastUpdate.published_at);
      const now = dayjs();
      const timeSinceResolved = now.diff(resolvedAt, 'minutes');

      if (timeSinceResolved < 30) {
        // Notify that this is now resolved
        addIncidentNotification(client, data.componentIdsToName, incident);
      }
    }

    // eslint-disable-next-line security/detect-object-injection
    delete notifiedIncidents[id];
  }

  // Same for maintenance
  const completeMaintenanceIds = Object.keys(notifiedMaintenances).filter(
    (id) => !seenIds.includes(id)
  );

  for (const id of completeMaintenanceIds) {
    // Need to go and fetch the maintenance, so we get the data associated with it
    // Remove maintenance from the list regardless of fetch working
    const maintenance = await fetchResolvedById<CompletedMaintenance>(id);
    if (maintenance) {
      // Don't notify if it has been more than 30m since it went to completed
      const lastUpdate = maintenance.updates[maintenance.updates.length - 1];
      const resolvedAt = dayjs(lastUpdate.published_at);
      const now = dayjs();
      const timeSinceComplete = now.diff(resolvedAt, 'minutes');

      if (timeSinceComplete < 30) {
        // Notify that this is now complete
        addIncidentNotification(client, data.componentIdsToName, maintenance);
      }
    }
    // eslint-disable-next-line security/detect-object-injection
    delete notifiedMaintenances[id];
  }

  // Save the data for the next poll
  sessionStorage.setItem(
    INCIDENT_STORAGE_KEY,
    JSON.stringify(notifiedIncidents)
  );

  localStorage.setItem(
    MAINTENANCE_STORAGE_KEY,
    JSON.stringify(notifiedMaintenances)
  );
}

/**
 *
 * @param client
 */
export async function statusPage(
  client: ApolloClient<any>,
  componentIds: UseStatusPage['componentIds']
): Promise<() => void> {
  const poller = setInterval(
    () => poll(client, componentIds).catch(captureError),
    POLL_INTERVAL
  );

  poll(client, componentIds).catch(captureError);

  return () => clearInterval(poller);
}
