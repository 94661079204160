import { useQuery } from '@apollo/client';

import { GET_NOTIFICATIONS } from '~/lib/graphql/localQueries/notifications';
import { Notification } from '~/lib/notifications/notification-types';

interface GetNotificationsResponse {
  notifications: Notification[];
}

/**
 *
 */
export function useNotifications(): Notification[] | undefined {
  const { data } = useQuery<GetNotificationsResponse>(GET_NOTIFICATIONS);

  return data?.notifications;
}
