import { useLocalisation } from './localisation';

export interface UseStatusPage {
  componentIds?: string[];
  poll: boolean;
  url: string;
}

export const STATUS_PAGE_URL = 'https://status.vgrid.io';
export const STATUS_PROXY_URL = `https://status.vgrid.io/proxy/status.vgrid.io`;

/**
 *
 */
export const useStatusPage = (): UseStatusPage => {
  const localisation = useLocalisation();

  const urlOverride = localisation.get('ui.global.statuspage.url');
  const componentIds = localisation.getDecoded<string[]>(
    'ui.global.statuspage.componentIds'
  );

  return {
    componentIds,
    poll: urlOverride === undefined,
    url: urlOverride ?? STATUS_PAGE_URL
  };
};
