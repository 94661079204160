import type { ApolloClient } from '@apollo/client';

import { UseStatusPage } from '../hooks/status-page';

import { anpr } from './subscriptions/anpr';
import { remoteCommands } from './subscriptions/remote-commands';
import { statusPage } from './subscriptions/status';
import { version } from './subscriptions/version';

/**
 *
 * @param client
 */
export async function initNotifications(
  client: ApolloClient<any>,
  statusPageInfo: UseStatusPage,
  anonymous?: boolean
): Promise<() => void> {
  const subscriptionPromises = [version(client)];
  const { componentIds, poll: pollStatusPage } = statusPageInfo;

  if (pollStatusPage) {
    subscriptionPromises.push(statusPage(client, componentIds));
  }

  if (!anonymous) {
    subscriptionPromises.push(anpr(client), remoteCommands(client));
  }

  const subscriptions = await Promise.all(subscriptionPromises);

  return (): void => {
    for (const unsubscribe of subscriptions) {
      unsubscribe();
    }
  };
}
