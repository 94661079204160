import type { IconName } from '@blueprintjs/icons';
import { IconNames } from '@blueprintjs/icons';

import { ResourceType } from '../resource-type';

interface UIField {
  icon: IconName;
  text: string;
}

/**
 * Map Resource Types to UI elements (icon, text)
 */
export const resourceTypeUIData: Record<ResourceType, UIField> = {
  ANPR_POI: { icon: IconNames.DRIVE_TIME, text: 'ANPR Plate of Interest' },
  ANPR_POI_GROUP: {
    icon: IconNames.DRIVE_TIME,
    text: 'ANPR Plates of Interest Groups'
  },
  ANPR_STREAM: { icon: IconNames.CAMERA, text: 'ANPR Streams' },
  CONTACT: { icon: IconNames.ANNOTATION, text: 'Contacts' },
  EDGE: { icon: IconNames.CELL_TOWER, text: 'Gateways' },
  EDGE_GROUP: { icon: IconNames.GLOBE_NETWORK, text: 'Gateway Groups' },
  ORGANISATION: { icon: IconNames.OFFICE, text: 'Organisation' },
  PERMISSION: { icon: IconNames.LOCK, text: 'Permission' },
  SITE: { icon: IconNames.MAP_MARKER, text: 'Sites' },
  SITE_GROUP: { icon: IconNames.FOLDER_CLOSE, text: 'Site Groups' },
  SSO_GROUP: { icon: IconNames.INHERITED_GROUP, text: 'SSO Groups' },
  STREAM: { icon: IconNames.MOBILE_VIDEO, text: 'Streams' },
  USER: { icon: IconNames.PERSON, text: 'Users' },
  USER_GROUP: { icon: IconNames.PEOPLE, text: 'User Groups' },
  VAULT_UPLOAD: { icon: IconNames.BOX, text: 'Vault Upload' },
  VAULT_UPLOAD_GROUP: { icon: IconNames.PROJECTS, text: 'Vault Upload Groups' },
  VMS: { icon: IconNames.CARGO_SHIP, text: 'VMSes' }
};
