import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { usePermissions } from '../../../lib/hooks/permissions';
import { useRouteUpdate } from '../../../lib/hooks/route-update';
import { SignOut } from '../../atoms/SignOut';
import { BetaFeatures } from '../../pages/account/BetaFeatures';
import Devices from '../../pages/account/Devices';
import Events from '../../pages/account/Events';
import Password from '../../pages/account/Password';
import TwoStepVerification from '../../pages/account/TwoStepVerification';
import UserAccount from '../../pages/account/UserAccount';
import { Welcome } from '../../pages/account/Welcome';
import AnprPoiGroup from '../../pages/admin/AnprPoiGroup';
import AnprPoiGroups from '../../pages/admin/AnprPoiGroups';
import AnprStream from '../../pages/admin/AnprStream';
import AnprStreams from '../../pages/admin/AnprStreams';
import Contact from '../../pages/admin/Contact';
import Contacts from '../../pages/admin/Contacts';
import Edge from '../../pages/admin/Edge';
import EdgeGroup from '../../pages/admin/EdgeGroup';
import EdgeGroups from '../../pages/admin/EdgeGroups';
import Edges from '../../pages/admin/Edges';
import Log from '../../pages/admin/Log';
import Logs from '../../pages/admin/Logs';
import Sessions from '../../pages/admin/Sessions';
import Site from '../../pages/admin/Site';
import SiteGroup from '../../pages/admin/SiteGroup';
import SiteGroups from '../../pages/admin/SiteGroups';
import Sites from '../../pages/admin/Sites';
import SsoGroup from '../../pages/admin/SsoGroup';
import SsoGroups from '../../pages/admin/SsoGroups';
import Stream from '../../pages/admin/Stream';
import Streams from '../../pages/admin/Streams';
import SuperAdminDashboard from '../../pages/admin/SuperAdminDashboard';
import User from '../../pages/admin/User';
import UserGroup from '../../pages/admin/UserGroup';
import UserGroups from '../../pages/admin/UserGroups';
import { Users } from '../../pages/admin/Users';
import Vms from '../../pages/admin/Vms';
import Vmses from '../../pages/admin/Vmses';
import Alert from '../../pages/anpr/Alert';
import Alerts from '../../pages/anpr/Alerts';
import Export from '../../pages/anpr/Export';
import ExportApproval from '../../pages/anpr/ExportApproval';
import { Insights } from '../../pages/anpr/Insights';
import { PlatesOfInterest } from '../../pages/anpr/PlatesOfInterest';
import QuickSearch from '../../pages/anpr/QuickSearch';
import RealTime from '../../pages/anpr/RealTime';
import { Notifications as NotificationsPage } from '../../pages/notifications/Notifications';
import { NotificationsSettings } from '../../pages/notifications/NotificationsSettings';
import StreamView from '../../pages/streams';
import Vault from '../../pages/vault/Vault';
import VaultUpload from '../../pages/vault/VaultUpload';

import { AuthenticatedIndex } from './AuthenticatedIndex';
import { ProtectedRoute } from './ProtectedRoute';

/**
 *
 */
export const AuthenticatedRoutes: FC = () => {
  useRouteUpdate();
  const { admin, anpr, hasStreams, hasVault } = usePermissions();

  return (
    <Routes>
      <Route index={true} element={<AuthenticatedIndex />} />

      {/* SIGN OUT FOR FORGOT PASSWORD AND ACCOUNT ACTIVATION -------------- */}

      <Route path="/activate/:token/:email" element={<SignOut />} />

      <Route path="/forgot-password/:token/:email" element={<SignOut />} />

      {/* ACCOUNT --------------------------------------------------------- */}

      <Route path="/account" element={<UserAccount />} />

      <Route path="/account/beta-features" element={<BetaFeatures />} />

      <Route
        path="/account/change-password"
        element={<Navigate to="/security/password" />}
      />

      <Route path="/security" element={<Navigate to="/account" />} />

      <Route path="/security/devices" element={<Devices />} />

      <Route path="/security/events" element={<Events />} />

      <Route path="/security/password" element={<Password />} />

      <Route
        path="/security/two-step-verification"
        element={<TwoStepVerification />}
      />

      <Route path="/welcome" element={<Welcome />} />

      {/* NOTIFICATIONS --------------------------------------------------- */}

      <Route path="/notifications" element={<NotificationsPage />} />

      <Route
        path="/notifications/settings"
        element={<NotificationsSettings />}
      />

      {/* STREAMS --------------------------------------------------------- */}

      <Route
        path="/streams/*"
        element={
          <ProtectedRoute allowed={hasStreams}>
            <StreamView />
          </ProtectedRoute>
        }
      />

      {/* VAULT ----------------------------------------------------------- */}

      <Route
        path="/vault"
        element={
          <ProtectedRoute allowed={hasVault || anpr.export}>
            <Vault />
          </ProtectedRoute>
        }
      />

      <Route
        path="/vault/*"
        element={
          <ProtectedRoute allowed={hasVault}>
            <VaultUpload />
          </ProtectedRoute>
        }
      />

      {/* ANPR ----------------------------------------------------------- */}

      <Route
        path="/anpr/alerts"
        element={
          <ProtectedRoute allowed={anpr.alerts}>
            <Alerts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/alerts/:id"
        element={
          <ProtectedRoute allowed={anpr.alerts}>
            <Alert />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/insights"
        element={
          <ProtectedRoute allowed={anpr.insights}>
            <Insights />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/export"
        element={
          <ProtectedRoute allowed={anpr.export}>
            <Export />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/plates-of-interest/*"
        element={
          <ProtectedRoute allowed={anpr.platesOfInterest}>
            <PlatesOfInterest />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/real-time"
        element={
          <ProtectedRoute allowed={anpr.realTime}>
            <RealTime />
          </ProtectedRoute>
        }
      />

      <Route
        path="/anpr/search"
        element={
          <ProtectedRoute allowed={anpr.search}>
            <QuickSearch />
          </ProtectedRoute>
        }
      />

      <Route
        path="/vault-anpr-approval"
        element={<ExportApproval standalone={false} />}
      />

      {/* ADMIN ---------------------------------------------------------- */}

      <Route path="/admin" element={<AuthenticatedIndex />} />

      <Route
        path="/admin/plates-of-interest-groups"
        element={
          <ProtectedRoute allowed={admin.anprPoiGroups}>
            <AnprPoiGroups />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/plates-of-interest-groups/:id"
        element={
          <ProtectedRoute allowed={admin.anprPoiGroups}>
            <AnprPoiGroup />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/anpr-streams"
        element={
          <ProtectedRoute allowed={admin.streams}>
            <AnprStreams />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/anpr-streams/:id"
        element={
          <ProtectedRoute allowed={admin.streams}>
            <AnprStream />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/contacts"
        element={
          <ProtectedRoute allowed={admin.contacts}>
            <Contacts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/contacts/:id"
        element={
          <ProtectedRoute allowed={admin.contacts}>
            <Contact />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/gateways"
        element={
          <ProtectedRoute allowed={admin.edges}>
            <Edges />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/gateways/:id"
        element={
          <ProtectedRoute allowed={admin.edges}>
            <Edge />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/gateway-groups"
        element={
          <ProtectedRoute allowed={admin.edgeGroups}>
            <EdgeGroups />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/gateway-groups/:id"
        element={
          <ProtectedRoute allowed={admin.edgeGroups}>
            <EdgeGroup />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/logs"
        element={
          <ProtectedRoute allowed={admin.logs}>
            <Logs />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/logs/:id"
        element={
          <ProtectedRoute allowed={admin.logs}>
            <Log />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/sessions"
        element={
          <ProtectedRoute allowed={admin.sessions}>
            <Sessions />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/sites"
        element={
          <ProtectedRoute allowed={admin.sites}>
            <Sites />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/sites/:id"
        element={
          <ProtectedRoute allowed={admin.sites}>
            <Site />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/site-groups"
        element={
          <ProtectedRoute allowed={admin.siteGroups}>
            <SiteGroups />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/site-groups/:id"
        element={
          <ProtectedRoute allowed={admin.siteGroups}>
            <SiteGroup />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/sso-groups"
        element={
          <ProtectedRoute allowed={admin.ssoGroups}>
            <SsoGroups />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/sso-groups/:id"
        element={
          <ProtectedRoute allowed={admin.ssoGroups}>
            <SsoGroup />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/streams"
        element={
          <ProtectedRoute allowed={admin.streams}>
            <Streams />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/streams/:id"
        element={
          <ProtectedRoute allowed={admin.streams}>
            <Stream />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/superadmin"
        element={
          <ProtectedRoute allowed={admin.superadmin}>
            <SuperAdminDashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/users"
        element={
          <ProtectedRoute allowed={admin.users}>
            <Users />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/users/:id"
        element={
          <ProtectedRoute allowed={admin.users}>
            <User />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/user-groups"
        element={
          <ProtectedRoute allowed={admin.userGroups}>
            <UserGroups />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/user-groups/:id"
        element={
          <ProtectedRoute allowed={admin.userGroups}>
            <UserGroup />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/vmses"
        element={
          <ProtectedRoute allowed={admin.vms}>
            <Vmses />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/vmses/:id"
        element={
          <ProtectedRoute allowed={admin.vms}>
            <Vms />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<AuthenticatedIndex />} />
    </Routes>
  );
};
