import { FC } from 'react';

import { useStatusPage } from '../../../lib/hooks/status-page';

import styles from './StatusPageLink.module.scss';

export const StatusPageLink: FC = () => {
  const { url } = useStatusPage();

  return (
    <div className={styles.statusPageLink}>
      <a href={url} target="__blank">
        vGRID Status Page
      </a>
    </div>
  );
};
