import { useApolloClient, useMutation } from '@apollo/client';
import { Classes } from '@blueprintjs/core';
import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useNotifications } from '~/lib/hooks/notifications/notifications';

import { CLEAR_NOTIFICATIONS } from '../../../lib/graphql/localQueries/notifications';
import { useStatusPage } from '../../../lib/hooks/status-page';
import { handlers } from '../../../lib/notifications/handlers';
import { initNotifications } from '../../../lib/notifications/init';

import { Toast } from './Toast';

interface NotificationsProps {
  anonymous?: boolean;
}

/**
 *
 */
export const Notifications: FC<NotificationsProps> = (props) => {
  const client = useApolloClient();
  const statusPageInfo = useStatusPage();

  useEffect(() => {
    const subscriptions = initNotifications(
      client,
      statusPageInfo,
      props.anonymous
    );

    return () => {
      subscriptions
        .then((unsubscribe) => unsubscribe())
        .catch((error: Error) => console.error(error));
    };
  }, [client]);

  const notifications = useNotifications();
  const [clearAll] = useMutation(CLEAR_NOTIFICATIONS);
  const { pathname } = useLocation();

  if (!notifications?.length || pathname === '/notifications') {
    return null;
  }

  const classNames = [Classes.TOAST_CONTAINER, 'vg-notification__list'];
  const clearClassNames = [Classes.TOAST, 'vg-notification__clear'];
  const moreClassNames = [Classes.TOAST, 'vg-notification__more'];

  const more = notifications.length - 5;

  return (
    <div className={classNames.join(' ')}>
      {notifications.slice(0, 5).map((notification) => (
        <Toast key={notification.id} {...notification} />
      ))}
      <div className="vg-notification__buttons">
        {notifications.length > 5 && !props.anonymous && (
          <div
            className={moreClassNames.join(' ')}
            onClick={() => handlers.link(null, '/notifications')}
            tabIndex={0}
          >
            <span>
              View {more} more notification{more === 1 ? '' : 's'}
            </span>
          </div>
        )}
        {notifications.length > 1 && (
          <div
            className={clearClassNames.join(' ')}
            onClick={() => clearAll()}
            tabIndex={0}
          >
            <span>Clear all</span>
          </div>
        )}
      </div>
    </div>
  );
};
